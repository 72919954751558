<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="代理商管理"
      left-text="返回"
      :right-text="!state.loading ? '添加' : ''"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="!state.loading && $router.push({ name: 'AgentForm' })"
    />
    <van-list
      v-model:loading="state.loading"
      v-model:error="state.error"
      finished-text="没有更多了"
      error-text="拉取失败，点击重新加载"
      :finished="state.finished"
      @load="loadPage"
    >
      <van-cell
        v-for="(item, index) in state.items"
        icon="manager-o"
        :key="index"
        :title="item.agent_name"
        :to="{ name: 'AgentDetail', query: { id: item.id } }"
        is-link
      />
    </van-list>
  </div>
</template>

<script>
import { reactive } from "vue";
import { List, Toast, Field } from "vant";
import { getSubAgent } from "@/api/agent.service";
export default {
  components: {
    [List.name]: List,
    [Field.name]: Field,
  },

  setup() {
    const state = reactive({
      page: 1,
      items: [],
      loading: true,
      error: false,
      finished: false,
      empty: true,
      empty_text: "您无权访问当前页面",

      // 选择框
      station_id: 0,
      agent_name: "",
      columns: [{ values: [], defaultIndex: 0 }],
      stationList: [],
    });

    const loadPage = async () => {
      try {
        const { result } = await getSubAgent({
          page: state.page,
        });
        if (result.items.length > 0) {
          state.items.push(...result.items);
          state.page++;
        }
        if (!result.hasNext || result.items.length == 0) {
          state.finished = true;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.error = true;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    return { state, loadPage };
  },
};
</script>
